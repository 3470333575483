import 'magnific-popup';

// Apple
var loaded = false;
document.addEventListener( 'musickitloaded', function () {
	if ( !loaded ) {
		loaded = true;
		let AmkPlaylists = ['pl.47f4ac538e39489883e4999c96bed23b'];
		let AmkAlbums = [];
		// Create a new SMEAppleMusic object with your parameters
		let sm = new SMEAppleMusic( {
			ae: {
				ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
				brand_id: '3448125',
				segment_id: '838847',
				activities: '{"actions":{"presave":28800},"mailing_list_optins":{"a0S1p00000TM5OvEAL":29006}}'
			},
			am: {
				dev_token: '',
				save_mode: 'library',
				custom_playlist_name: '',
				resources: {
					playlists: AmkPlaylists,
					albums: AmkAlbums
				}
			},
			sf: {
				form: '49877',
				default_mailing_list: 'a0S1p00000TM5OvEAL'
			},
			smf: {
				campaign_id: '49877',
				campaign_key: 'e8cd91524f3988581b330712f7c2892a'
			}
		} );
		document.getElementById( 'apple_music_form' ).addEventListener( 'submit', function ( e ) {
			e.preventDefault();
			// Grab the email address value
			const email = document.getElementById( 'apple_music_email_address' ).value;
			// Pass the email address and opt in status
			// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
			sm.doActions( email, true ).then( function ( res ) {
				$.magnificPopup.close();
				$.magnificPopup.open( {
					items: {
						src: '#thanks',
						type: 'inline'
					}
				} );
			} ).catch( function ( err ) {
				alert( 'An error has occurred!' );
			} );
		} );
	}
} );

$( document ).ready( function ( $ ) {
	// Apple
	$( '.apple-pre-add' ).on( 'click', function ( e ) {
		e.preventDefault();
		$.magnificPopup.open( {
			items: {
				src: '#apple',
				type: 'inline'
			}
		} );
	} );

	// Spotify
	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function ( callback, form_id ) {
			let hash;
			let hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
			for ( let i = 0; i < hashes.length; i ++ ) {
				hash = hashes[i].split( '=' );
				this.url_vars.push( hash[0] );
				this.url_vars[hash[0]] = hash[1];
			}
			this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
			this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
			this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
			this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
			this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
			if ( typeof callback === 'function' ) {
				callback( this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message );
			}
		}
	};

	SMEPreSave.getURLVars( function ( state, ae_member_id, spotify_user, deezer_user, message ) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if ( state === 'success' || state === 'thank-you' ) {
			$.magnificPopup.open( {
				items: {
					src: '#thanks',
					type: 'inline'
				}
			} );
		}
	} );
} );